import { FormsModule } from '@angular/forms';

import { Component, OnInit } from '@angular/core';
import { DataService } from '../../app/data.service';
import { AccordionComponent } from '../accordion/accordion.component';
import { AngularFireStorage, AngularFireStorageReference, AngularFireUploadTask } from 'angularfire2/storage';
import { Observable } from 'rxjs';
import { ToastrManager } from 'ng6-toastr-notifications';
import { FileUploader } from 'ng2-file-upload/ng2-file-upload';

@Component({
  selector: 'app-muzeum',
  templateUrl: './muzeum.component.html',
  styleUrls: ['./muzeum.component.css']
})
export class MuzeumComponent implements OnInit {

  editorConfig={
  "editable": true,
  "spellcheck": true,
  "height": "auto",
  "minHeight": "0",
  "width": "auto",
  "minWidth": "0",
  "translate": "yes",
  "enableToolbar": true,
  "showToolbar": true,
  "placeholder": "Enter text here...",
  "imageEndPoint": "",
  "toolbar": [
      ["bold", "italic", "underline", "strikeThrough"],
      ["fontName", "fontSize", "color"],
      ["justifyLeft", "justifyCenter", "justifyRight", "justifyFull", "indent", "outdent"],
      // ["cut", "copy", "delete", "removeFormat", "undo", "redo"],
      // ["paragraph", "blockquote", "removeBlockquote", "horizontalLine", "orderedList", "unorderedList"],
      ["paragraph"]
      // []
  ]
}






  htmlContent="";
  item={"check":true};
  showLangSelect = false;
  showAddEx = false;
  showAddSp = false;
  showAddNewSp = false;
  imgPrivew;
  srcOk = false;
  srcDisabled = false;
  audioPlayer;

  addBtnDisabeld=true;
  progImg = false;
  progImgSound = false;
  ref: AngularFireStorageReference;
  task: AngularFireUploadTask;
  task1: AngularFireUploadTask;
  uploadProgress: Observable<number>;
  downloadURL: Observable<string>;


  ref2: AngularFireStorageReference;
  task2: AngularFireUploadTask;
  uploadProgress2: Observable<number>;
  downloadURL2: Observable<string>;

  AddLangSelect = 'he';
  empty_choosenEx = {
    name: "",
    discription: "",
    autoPlay: false,
    bgImg: "",
    btnEnter: "",
    choosenColor: "",
    colors: [],
    openScreen: false,
    password: [],
    spotType: "",
    spots: [],
    muzeum: this._DataService.Meuzium._id,
  }

  empty_choosenSpot = {
    ExhibitionsID: this._DataService.choosenEx._id,
    bgImg: "",
    choosenColor: "",
    code: '000',
    discription: "",
    lang: "",
 
    colors: [],
    visited: false,
    src: "",
    name: "",
    _id: "",
    options: [],
    muzeum: this._DataService.Meuzium._id,
  };
  constructor(public toastr: ToastrManager, private _DataService: DataService, private afStorage: AngularFireStorage) {
    /// this.choosenLang = this._DataService.Meuzium.lang.filter(element => element.code == this._DataService.Meuzium.choosenLang);

  }
  ngOnInit() {


  }

  credit(id){
    var self=this;
    if (id) {
    this._DataService.spotEdit(this._DataService.choosenSpot.id, "credit", this._DataService.choosenSpot.credit).then(function () {
      console.log("Document successfully written!");

      self.showSuccess("התוכן עודכן בהצלחה");
    }).catch(function (error) {
        console.error("Error writing document: ", error);
        self.showError("היתה בעיה בשמירת התכון אנה פנה למנהל המערכת");
      });
    // this._DataService.spotEdit(id, type, event.target.value).subscribe((res: any) => {
    //   console.log('-----res----', res);
    // });

    }
  
  }

  textChange(id){
    var self=this;
    if (id) {
    this._DataService.spotEdit(this._DataService.choosenSpot.id, "textData", this._DataService.choosenSpot.textData).then(function () {
      console.log("Document successfully written!");

      self.showSuccess("התוכן עודכן בהצלחה");
    }).catch(function (error) {
        console.error("Error writing document: ", error);
        self.showError("היתה בעיה בשמירת התכון אנה פנה למנהל המערכת");
      });
    // this._DataService.spotEdit(id, type, event.target.value).subscribe((res: any) => {
    //   console.log('-----res----', res);
    // });

    }
  
  }
  changedValdata(type, event, id){


    if (id) {
      console.log('type', type);
      console.log('event', event.target.value);
      console.log('id', id);
      this._DataService.spotEdit(id, type, event.target.value).then(function () {
        console.log("Document successfully written!");

        this.showSuccess("התוכן עודכן בהצלחה");
      })
        .catch(function (error) {
          console.error("Error writing document: ", error);
          this.showError();
        });
      // this._DataService.spotEdit(id, type, event.target.value).subscribe((res: any) => {
      //   console.log('-----res----', res);
      // });



    }

  }



  changedVal(type, event, id) {
    console.log(type,"type", event,"event", id);
   // console.log(type, event.target.value, id)
    if (id) {
      console.log('id', id);
      console.log('type', type);
      if (type == 'choosenColor') {
        var vall = event;
      } else {
        var vall = event.target.value;
      }
    
      console.log(this._DataService.choosenSpot, "this._DataService.choosenSpot");
      //  console.log('event.target.value', event.target.value);
      var self = this;
      this._DataService.spotEdit(id, type,vall).then(function () {
        console.log("Document successfully written!");

        self.showSuccess("התוכן עודכן בהצלחה");
      })
        .catch(function (error) {
          console.error("Error writing document: ", error);
          self.showError("בעיה בעדכון התוכן");
        });


      // this._DataService.spotEdit(id, type, vall).subscribe((res: any) => {
      //   console.log('-----res----', res);
      //   this.showSuccess("התוכן עודכן בהצלחה");
      // } ,err => console.log( this.showError()),
      // () => console.log('yay'));
    }
  }

  validationAddSpot(){

    
    console.log("ww ",this._DataService.choosenSpot)
    if((this._DataService.choosenSpot.ExhibitionsID)&&(this._DataService.choosenSpot.lang)){
      this.addBtnDisabeld=false;

    
    }else{
      this.addBtnDisabeld=true;
    }
  }



  saveCheckBox(type, event, id){
    var self=this;

  
    if (type == "showAudio") {
      console.log("event.target.value ",event.target.checked)
      this._DataService.choosenSpot.showAudio = event.target.checked;

    } 
    if (type == "showText") {
      console.log("event.target.value ",event.target)
      this._DataService.choosenSpot.showText = event.target.checked;

    } 

    if(id){
    this._DataService.spotEdit(id, type, event.target.checked).then(function () {
      console.log("Document successfully written!");

      self.showSuccess("התוכן עודכן בהצלחה");
    }).catch(function (error) {
        console.error("Error writing document: ", error);
        self.showError("היתה בעיה בשמירת התכון אנה פנה למנהל המערכת");
      });
 

    }


  }
  changeExeSelect(type, event, id) {


    if (type == "ExhibitionsID") {
      console.log("event.target.value ",event.target.value)
      this._DataService.choosenSpot.ExhibitionsID = event.target.value;

      this.validationAddSpot();
    } 
    
    if (type == "lang") {
      this._DataService.choosenSpot.lang = event.target.value;
      this.validationAddSpot();
    }


    if (id) {
      console.log('type', type);
      console.log('event', event.target.value);
      console.log('id', id);
      this._DataService.spotEdit(id, type, event.target.value).then(function () {
        console.log("Document successfully written!");

        this.showSuccess("התוכן עודכן בהצלחה");
      })
        .catch(function (error) {
          console.error("Error writing document: ", error);
          this.showError();
        });
      // this._DataService.spotEdit(id, type, event.target.value).subscribe((res: any) => {
      //   console.log('-----res----', res);
      // });



    }

  }

  changedValExe(type, event, id) {

    console.log("type",type,event,id);
    if (id) {
      console.log('id', id);
      console.log('type', type);
      if (type == 'choosenColor') {
        var vall = event;
      } else {
        var vall = event.target.value;
      }
      if (type == 'lang') {
        var vall =event.target.value;
      } 
      console.log(vall);
 console.log('event.target.value', type, event, id);
      
      this._DataService.ExhibitionEdit(id, type, vall).then((res: any) => {
        console.log('-----res----', res);
        this.showSuccess("התוכן עודכן בהצלחה");
      }).catch(function (error) {
        console.error("Error writing document: ", error);
        this.showError("התוכן עודכן בהצלחה");
      });;;
      // this._DataService.ExhibitionEdit(id, type, vall).subscribe((res: any) => {
      //   console.log('-----res----', res);
      // });
    }
  }

  changeExhibitionEditSelect(type, event, id) {
    if (id) {
      console.log('type', type);
      console.log('event', event.target.value);
      console.log('id', id);
      this._DataService.ExhibitionEdit(id, type, event.target.value).then((res: any) => {
        console.log('-----res----', res);
      });


    }
  }



  clickLng(langCode) {
    console.log(langCode);
    this.AddLangSelect = langCode;
    this._DataService.Meuzium.choosenLang =langCode;
    this._DataService.choosenLang = this._DataService.Meuzium.lang.filter(element => element.code == langCode);
    this._DataService.choosenLang = this._DataService.choosenLang[0];
    console.log(this._DataService.choosenLang);
    this.showLangSelect = false;
    this._DataService.hide.exhibitions = true;
    this._DataService.choosenEx = this.empty_choosenEx;
    this._DataService.choosenSpot = this.empty_choosenSpot;
    this._DataService.hide.spots = true;
    //http://localhost:8100/#/muzeum/ComicsHolon/he
    //  this._DataService.url = this._DataService.publicURL + '/#/muzeum/' + this._DataService.Meuzium.urlCode + '/' + this._DataService.choosenLang.code;

  }

  clickExe(ex) {
    console.log('exID', ex);

    this._DataService.choosenEx={};

    this._DataService.choosenEx = ex;
    this.showAddEx = false;
    this._DataService.hide.exhibitions = false;
    this._DataService.choosenSpot = this.empty_choosenSpot;
    this._DataService.hide.spots = true;
    this.showAddNewSp = true;
    //http://localhost:8100/#/exhibition/ComicsHolon/5b93bc0fc8445104910ae284
    // this._DataService.url = this._DataService.publicURL + '/#/exhibition/' + this._DataService.Meuzium.urlCode + '/' + this._DataService.choosenEx._id;
  }



  clickSpot(sp) {

    this._DataService.choosenSpot = sp;
    this.selectedOptionNewSpot = sp.lang;
    this._DataService.hide.spots = false;
    this._DataService.modal.spots = true;


    console.log("this._DataService.choosenSpot,",this._DataService.choosenSpot);

    if (sp.src) {
      this.srcOk = true;
      this.srcDisabled = true;
      this.audioPlayer = new Audio(sp.src);
    } else {

      this.srcDisabled = false;
      this.srcOk = false;
    }
    // http://localhost:8100/#/spot/ComicsHolon/5b96684e174f465f8f200b68
    //  this._DataService.url = this._DataService.publicURL + '/#/spot/' + this._DataService.Meuzium.urlCode + '/' + this._DataService.choosenSpot._id;

  }

  changeLangSelect(event) {
    console.log('event.target.value', event.target.value);
    this.AddLangSelect = event.target.value;
  }


  addLang(event) {
    console.log(event);
    this.showLangSelect = true;
    this._DataService.choosenLang = {
      name: "",
      discription: "",
      code: "",
      exhibitions: [],
    };
  }

  clickAddLangBtn(event) {
    console.log(event);
    console.log('add to database + animation');
    console.log(this._DataService.choosenLang);
    console.log(this.AddLangSelect);
    this.showLangSelect = false;
    this._DataService.hide.spots = true;

  }

  clickAddExhibition(event) {
    console.log(event);

 
    this.showAddEx = true;
    this.showAddNewSp = false;
    this._DataService.hide.exhibitions = false;
    this._DataService.choosenEx = this.empty_choosenEx;
    this._DataService.choosenSpot = this.empty_choosenSpot;
    this._DataService.modal.exhibitions = true;
console.log("this._DataService.choosenEx ",this._DataService.choosenEx );
  }

  clickAddSpot(event) {
    window.scroll(0,0);
    console.log(event);
    this._DataService.choosenSpot.bgImg="";
    this._DataService.choosenSpot={
      ExhibitionsID:"",
      bgImg:"",
      choosenColor:"",
      code:'000',
      discription:"",
      lang:"",
      showAudio:true,
      showText:true,
      colors:[],
      visited:false,
      src: "",
      name:"",
      textData:"",
      _id:"",
      id:"",
      options:[],
      muzeum:"",
    };;
    this.showAddSp = true;
    this._DataService.hide.spots = false;
    this._DataService.modal.spots = true;
    console.log("---- ", this._DataService.choosenEx)
    // this._DataService.choosenSpot = this.empty_choosenSpot;
    // console.log("this._DataService.choosenEx.bgImg", this._DataService.getExhibitionByID,this._DataService.choosenSpot);
    this._DataService.choosenSpot.ExhibitionsID = this._DataService.choosenEx.id;
    this._DataService.choosenSpot.lang = this._DataService.choosenEx.lang;
    console.log("---- ", this._DataService.choosenEx,this._DataService.choosenSpot)
    this.srcDisabled = false;
    this.srcOk = true;
    this.validationAddSpot();
  }

  showModalApp() {
    console.log("dsad");
    this._DataService.modal.app = true;
  }


  showModalEx() {
    console.log("dsad");
    this._DataService.modal.exhibitions = true;
  }

  ExDelete(id) {
    console.log('spotID to delelte: ', id);
    if (confirm('בטוחים שתרצו למחוק?')) {


      this._DataService.ExDelete(id).then((res: any) => {
          console.log("res",res)
          this.closeModal(id);
      
      }).catch(function (error) {
        console.error("Error writing document: ", error);
        alert('משהו השתבש נסו שוב');
      });;





      // this._DataService.ExDelete(id).subscribe((res: any) => {
      //   console.log('-----res----', res);
      //   if (res.success) {
      //     console.log(id)
      //     for (var i = 0; i < this._DataService.choosenLang.exhibitions.length; i++)
      //       if (this._DataService.choosenLang.exhibitions[i]._id == id) {
      //         this._DataService.choosenLang.exhibitions.splice(i, 1);
      //         break;
      //       }
      //     this.closeModal(id);
      //   } else {
      //     alert('משהו השתבש נסו שוב');
      //   }
      // });
    }//end of confirm
  }


  spotDelete(id) {
    console.log('spotID to delelte: ', id);
    if (confirm('בטוחים שתרצו למחוק?')) {
      var self = this;
      this._DataService.spotDelete(id).then((res: any) => {
        console.log('-----res----', res);

        console.log(id)

        self.closeModal(id);

      }).catch(function (error) {
        console.error("Error writing document: ", error);
        alert('משהו השתבש נסו שוב');
      });;



      // this._DataService.spotDelete(id).subscribe((res: any) => {
      //   console.log('-----res----', res);
      //   if (res.success) {
      //     console.log(id)
      //     for (var i = 0; i < this._DataService.choosenEx.spots.length; i++)
      //       if (this._DataService.choosenEx.spots[i]._id == id) {
      //         this._DataService.choosenEx.spots.splice(i, 1);
      //         break;
      //       }
      //     this.closeModal(id);
      //   } else {
      //     alert('משהו השתבש נסו שוב');
      //   }
      // });
    }//end of confirm
  }

  closeModal(e) {
    this.showAddSp = false;
    this._DataService.modal.exhibitions = false;
    this._DataService.modal.spots = false;
    this._DataService.modal.app = false;
  }

  playAudio() {
    console.log(this.audioPlayer);
    this.audioPlayer.play();
    this.srcOk = false;
  }

  pauseAudio() {
    this.srcOk = true;
    this.audioPlayer.pause();
  }
  saveEx(event) {
    console.log(this._DataService.Meuzium, this._DataService.choosenEx.muzeum);
    this._DataService.choosenEx.muzeum = this._DataService.Meuzium.id;

    var self=this;
    this._DataService.choosenEx.spotType = 'option1';
    this._DataService.choosenEx.lang = this._DataService.choosenLang.code;
    console.log("choosenEx",this._DataService.choosenEx);
    var newEx=this._DataService.choosenEx;
    this._DataService.addExFirebase(this._DataService.choosenEx).then((res: any) => {
      console.log('-----res----', res.id);
      newEx.id=res.id;
      this._DataService.ExhibitionAddId(res.id,newEx).then((res: any) => {
        console.log('-----res----', res);
        self.showSuccess("התוכן עודכן בהצלחה");
      }).catch(function (error) {
        console.error("Error writing document: ", error);
        self.showError("התוכן עודכן בהצלחה");
      });;;
      console.log('this._DataService.choosenLang.exhibitions----before', this._DataService.choosenLang.exhibitions);
      //this._DataService.choosenLang.exhibitions.push(this._DataService.choosenEx);
      this.closeModal('sadas');
      console.log('this._DataService.choosenLang.exhibitions----after', this._DataService.choosenLang.exhibitions);

    });
  }

  selectedOptionNewSpot = this._DataService.choosenEx.lang;
  saveSp(event) {
    console.log(event);

    this._DataService.choosenSpot.muzeum = this._DataService.Meuzium.id;
  
    // this._DataService.choosenSpot.lang = this.selectedOptionNewSpot;
    console.log('this._DataService.choosenSpot', this._DataService.choosenSpot);
    console.log("saveSp", this._DataService.choosenSpot);

    


    var self = this;





    this._DataService.addSpotFire(this._DataService.choosenSpot).then(function (res) {
      console.log("Document written with ID: ", res.id);

      console.log('-----res----', res);

      // console.log('this._DataService.choosenEx._id', this._DataService.choosenEx._id);
      console.log('res.newSpot.ExhibitionsID.spot', self._DataService.choosenSpot);
      if (self._DataService.choosenEx.id == self._DataService.choosenSpot.ExhibitionsID) {




      // self._DataService.choosenEx.spots.push(self._DataService.choosenSpot);
      } else {
        console.log('not equal!!!!');

      }
      self._DataService.hide.spots = true;
      self._DataService.modal.spots = false;
      self.showSuccess("התחנה נוספה בהצלחה");

    })
      .catch(function (error) {
        console.error("Error adding document: ", error);
      });

    // this._DataService.addSpot(this._DataService.choosenSpot).subscribe((res: any) => {
    //   console.log('-----res----', res);
    //   console.log('this._DataService.choosenEx._id', this._DataService.choosenEx._id);
    //   console.log('res.newSpot.ExhibitionsID', res.newSpot.ExhibitionsID);
    //   if (this._DataService.choosenEx._id == res.newSpot.ExhibitionsID) {
    //     this._DataService.choosenEx.spots.push(res.newSpot);
    //   } else {
    //     console.log('not equal!!!!');
    //     console.log(this._DataService.choosenEx._id);
    //     console.log(res.newSpot.ExhibitionsID);
    //     console.log('not equal!!!!');
    //   }
    //   this._DataService.hide.spots = true;
    //   this._DataService.modal.spots = false;
    //   this.showSuccess("התחנה נוספה בהצלחה");

    // });

  }




  upload(event, x) {
    this.progImg = true;


    console.log("this._DataService.choosenSpot()");


    const id = Math.random().toString(36).substring(2);
    this.ref = this.afStorage.ref(id);
    this.task = this.ref.put(event.target.files[0]);
    // do you code after upload here

    this.uploadProgress = this.task.percentageChanges()
    var self = this;
    this.task.then(snap => {
      snap.ref.getDownloadURL().then(function (downloadURL) {


        if (x == "exhibition") {

console.log("self.showAddEx",self.showAddEx);

          if (self.showAddEx) {
            self._DataService.choosenEx.bgImg = downloadURL;
            this.imgPrivew=downloadURL;
    
          } else {


           // let objString = JSON.stringify(downloadURL);
          //  objString = self._DataService.b64EncodeUnicode(objString);

console.log("kssss",self._DataService.choosenEx.id, "bgImg", downloadURL);

            self._DataService.ExhibitionEdit(self._DataService.choosenEx.id, "bgImg", downloadURL).then((res: any) => {
              console.log('-----res----', res);
              self._DataService.choosenEx.bgImg = downloadURL;
              self.showSuccess("התוכן עודכן בהצלחה");
            });

            // self._DataService.ExhibitionEdit(self._DataService.choosenEx.id, "bgImg", objString).subscribe((res: any) => {
            //   console.log('-----res----', res.spot);
            //   self.showSuccess("התמונה עלתה בהצלחה");
            //   self._DataService.choosenEx.bgImg = res.spot.bgImg;
            // });


          }
        } else {


          if (self.showAddSp) {
            self._DataService.choosenSpot.bgImg = downloadURL;
          } else {

            self._DataService.choosenSpot.bgImg = downloadURL;
            self._DataService.spotEdit(self._DataService.choosenSpot.id, "bgImg", downloadURL)
              .then(function () {
                console.log("Document successfully written!");

                self.showSuccess("התוכן עודכן בהצלחה");
                self._DataService.choosenSpot.bgImg = downloadURL;
              })
              .catch(function (error) {
                console.error("Error writing document: ", error);
                self.showError("היתה בעיה בעדכון התוכן");
              });

            // self._DataService.spotEdit(self._DataService.choosenSpot._id, "bgImg", objString).subscribe((res: any) => {
            //   console.log('-----res----', res.spot);

            //   self._DataService.choosenSpot.bgImg = res.spot.bgImg;
            // });


          }
        }
        self.progImg = false;
      });
    })


  }

  uploadSound(event) {
    this.progImgSound = true;
    console.log("update sound",this._DataService.choosenSpot)

   

      console.log("step 1 ,",this._DataService.choosenSpot.id)
      const id = Math.random().toString(36).substring(2);
      this.ref2 = this.afStorage.ref(id);

      this.task2 = this.ref2.put(event.target.files[0]);
      // do you code after upload here

      this.uploadProgress2 = this.task2.percentageChanges()
      var self = this;
      this.task2.then(snap => {
        snap.ref.getDownloadURL().then(function (downloadURL) {
          downloadURL = downloadURL + ".mp3";
          console.log(downloadURL,self._DataService.choosenSpot);
          if (self._DataService.choosenSpot.id == undefined) {
            self.audioPlayer = new Audio(downloadURL);
            self._DataService.choosenSpot.src = downloadURL;
            self.srcDisabled = true;

            self.showSuccess("הסאונד על בהצלחה");
          } else {
            // let objString = JSON.stringify(downloadURL);
            // console.log(objString);
     

            self._DataService.choosenSpot.src = downloadURL;
            self._DataService.spotEdit(self._DataService.choosenSpot.id, "src",downloadURL)
              .then(function (res) {
                console.log("Document successfully written!");

                self.showSuccess("התוכן עודכן בהצלחה");
                console.log('-----res----', downloadURL);
                self.showSuccess("הסאונד עלה בהצלחה");
                self.srcDisabled = true;
                self.audioPlayer = new Audio(downloadURL);
                self.srcOk=true;
                self._DataService.choosenSpot.src = downloadURL;
              })
              .catch(function (error) {
                console.error("Error writing document: ", error);
                self.showError("היתה בעיה בקבוץ");
              });




            // self._DataService.spotEdit(self._DataService.choosenSpot._id, "src", objString).subscribe((res: any) => {
            //   console.log('-----res----', res.spot);
            //   self.showSuccess("הסאונד עלה בהצלחה");
            //   self.srcDisabled=true;
            //   self.audioPlayer = new Audio(res.spot.src);
            //   self._DataService.choosenSpot.src = res.spot.src;
            // });
          }
          self.progImgSound = false;
        });
      })
    

  }


  showSuccess(text) {
    this.toastr.successToastr(text, 'Success!');
  }

  showError(text) {
    this.toastr.errorToastr(text, '');
  }

  showWarning() {
    this.toastr.warningToastr('This is warning toast.', 'Alert!');
  }

  showInfo() {
    this.toastr.infoToastr('This is info toast.', 'Info');
  }

  showCustom() {
    this.toastr.customToastr('Custom Toast', null, { enableHTML: true });
  }

  showToast(position: any = 'top-left') {
    this.toastr.infoToastr('This is a toast.', 'Toast', { position: position });
  }

}


