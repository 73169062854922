import { Component, ContentChildren, QueryList, AfterContentInit } from '@angular/core';
import { PanelComponent } from '../panel/panel.component';
 
@Component({
  selector: 'accordion',
  template: '<ng-content></ng-content>'
})
export class AccordionComponent  implements AfterContentInit {
  @ContentChildren(PanelComponent) panels: QueryList<PanelComponent>;
  @ContentChildren(PanelComponent) panel: QueryList<PanelComponent>;
 
  ngAfterContentInit() {
    // Open the first panel
    this.panels.toArray()[0].opened = true;
    // Loop through all panels
    this.panels.toArray().forEach((panel => {
      // subscribe panel toggle event
      panel.toggle.subscribe(() => {
        // Open the panel
        this.openPanel(panel);
      });
    }));
  }
 
  openPanel(panel: PanelComponent) {
    // close all panels
    this.panels.toArray().forEach(p => p.opened = false);
    // open the selected panel
    panel.opened = true;
  }
}