import { Component } from '@angular/core';
import { DataService } from '../app/data.service';
import { Observable } from 'rxjs';
import { HttpClientModule } from '@angular/common/http'; 
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { AngularFirestore } from 'angularfire2/firestore';
import {  FileUploader } from 'ng2-file-upload/ng2-file-upload';
import { Router } from '@angular/router';



const URL = 'http://localhost:3000/api/upload';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'app';

username = "";
  password = "";
  public uploader:FileUploader = new FileUploader({url: URL, itemAlias: 'photo'});
  note:Observable<any[]>;
  constructor(  private router: Router,private af:AngularFirestore,private _DataService:DataService){

  }

  ngOnInit(){
    
  }

 


  clickEnter(event){
   
    this._DataService.getUserFirebase(this.username,this.password).subscribe((res:any) => {
        console.log(res);
        if(res){
        //  this.router.navigate(['/edit'])
          // this._DataService.loggedIn(res);
        }else{
          console.log('here not Auth',res);
        }
      });


    // this._DataService.getAuthUser(this.username,this.password).subscribe((res:any) => {
    //   console.log(res);
    //   if(res.success){
    //     console.log("do animation - loading");
    //     console.log("authGood",res.theResult);
    //     this._DataService.loggedIn(res.theResult[0]);
    //   }else{
    //     console.log('here not Auth',res);
    //   }
    // });
  }


  clickMuziuimData(){
    console.log(this._DataService.Meuzium);
  }
}
