import { AngularFirestore } from 'angularfire2/firestore';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';


@Injectable()
export class DataService {

  constructor(private af:AngularFirestore,public http: HttpClient) {
  }
  city="bat-yam";
  andartaModal=false;
  chosenSoldier="";

  choseAndartaArray=[];

  soldier = {
    andarta:[""],
    breavePlace: "",
    cityCode: this.city,
    date: "",
    fName: "",
    fallingdate: "",
    lName: "",
    level: "",
    mapB: "",
    
    perents: "",
    profile: "https://firebasestorage.googleapis.com/v0/b/yadlevnim.appspot.com/o/no_image_defult.jpg?alt=media&token=f7097dfc-e449-46c3-81e7-e872ecc733e3",
    story: "",
    unit: "",
    timeAdd:new Date(),
    unitLogo:{
          uName:"צה״ל",
          uImg:"https://firebasestorage.googleapis.com/v0/b/yadlevnim.appspot.com/o/%D7%A6%D7%94%D7%9C.png?alt=media&token=9541a2b0-31e7-4083-a3f1-f016870145b5"
        }
  }




//  ServerURL = "http://localhost:3000";
 ServerURL = "http://lm.experteam-game.co.il:3000";
 publicURL = "http://localhost:8101";


  options=["option1","option2"];

  modal = {
    exhibitions:false,
    spots:false,
    app:false,
    
  }

  page = {
    muzeum:false,
    langs:false,
    exhibitions:false,
    spots:false,
  }
  
  hide = {
    login:false,
    muzeum:true,
    langs:true,
    exhibitions:true,
    spots:true,
  }

  Meuzium:any = {
    ExhibitionStyle:"",
    logo:"",
    _id:"",
    urlCode:"",
    choosenLang:"",
  };
  choosenSpot:any={
    ExhibitionsID:"",
    bgImg:"",
    choosenColor:"",
    code:'000',
    discription:"",
    lang:"",
  
    colors:[],
    visited:false,
    src: "",
    name:"",
    _id:"",
    options:[],
    muzeum:"",
  };

  choosenEx:any={
    name:"",
    discription:"",
    autoPlay:false,
    bgImg:"",
    btnEnter:"",
    choosenColor:"",
    colors:[],
    openScreen:false,
    password: [],
    spotType:"",
    spots:[],
    muzeum:"",
  };

  choosenLang={
    name:"",
    discription:"",
    code:"",
    exhibitions: [],
  };

  url = this.publicURL + '/#/home/' + this.Meuzium.urlCode;


  loggedIn(MeuziumData){
    console.log('MeuziumData',MeuziumData);
    
    console.log(this.choosenSpot);
    // this.choosenSpot.muzeum = MeuziumData._id
    
    this.choosenSpot.muzeum = MeuziumData.id;
    console.log(this.choosenSpot);

    let self = this;
    this.Meuzium = MeuziumData[0];
    console.log(" MeuziumData.lang",  this.Meuzium );

    if(MeuziumData[0].lang){
    MeuziumData[0].lang.forEach(function (value,i) {
      // console.log(value);
      // console.log(MeuziumData);
      //getExhibitionByLang(id,lang)

console.log("value",value,i,MeuziumData[0].id);
self.getExhibitionByLangFireBase(MeuziumData[0].id,value["code"]).subscribe((res:any) => {
  console.log(self.Meuzium,"self.Meuzium,")
  
    console.log("res",res,i);
    // let self2 = self;
  
    if(self.Meuzium.lang[i].code=="he"){
      self.choosenLang = self.Meuzium.lang[i];
    }

console.log("res.theResult",res[0],  self.Meuzium.lang,i)
     self.Meuzium.lang[i].exhibitions=res;

            res.forEach(function (value2,i2){
              console.log(value2,"value2",i2);
              
             // console.log(value2,i2);
             // console.log(value2._id,value2.lang);
          //    getSpotByExebitionAndLang(id,lang){
               self.getSpotByExebitionAndLangFirebase(value2.id,value2.lang).subscribe((res2:any) => {
                console.log("res2",res2,);
                console.log( self.Meuzium.lang[i], i,i2);
                 self.Meuzium.lang[i].exhibitions[i2].spots = res2; 
               });//end of self.getSpotByExebitionAndLang
           });    //end of res.theResult.forEa
  
         });//end of self.getExhibitionByLang



//old code // 
    //   self.getExhibitionByLang(MeuziumData._id,value["code"]).subscribe((res:any) => {
    //  //   console.log(res);
    //    // let self2 = self;
    //   //  console.log(self.Meuzium.lang[i].code)
    //   //  console.log(self.Meuzium.choosenLang)
    //    if(self.Meuzium.lang[i].code==self.Meuzium.choosenLang){
    //      self.choosenLang = self.Meuzium.lang[i];
     
    //    }

    //     self.Meuzium.lang[i].exhibitions=res.theResult;
    //            res.theResult.forEach(function (value2,i2){
    //             // console.log(value2,i2);
    //             // console.log(value2._id,value2.lang);
    //          //   //    getSpotByExebitionAndLang(id,lang){
    //               self.getSpotByExebitionAndLang(value2._id,value2.lang).subscribe((res2:any) => {
    //                // console.log(res2);
    //                 self.Meuzium.lang[i].exhibitions[i2].spots = res2.theResult; 
    //               });//end of self.getSpotByExebitionAndLang
    //           });    //end of res.theResult.forEa
     
    //         });//end of self.getExhibitionByLang
// end old code//

  });//end of getExhibitionByLang
}
this.hide.login = true;
this.hide.muzeum = false;
this.url = this.publicURL + '/#/home/' + this.Meuzium.urlCode;
console.log('Meuzium',this.Meuzium);
this.hide.langs = false;
this.chnagePage("exhibitions");
  }


  chnagePage(pageShow){
    this.page = {
      muzeum:false,
      langs:false,
      exhibitions:false,
      spots:false,
    }
    this.page[pageShow] = true;
  }


  getAuthUser(username,password){
    console.log('getAuthUser',username,password);



    return this.http.get(this.ServerURL + "/api/Auth/" + username + "/"+ password);
  }
 

    
    getUserFirebase(username,password){
      return  this.af.collection('Users',
      ref => ref.where('username', '==', username).where("password","==",password)
      ).valueChanges();

    }
    loadFloorsByid(id){
      return  this.af.collection('floors',ref => ref.where('buildingid', '==', id)).snapshotChanges().pipe(
        map(actions => {
          return actions.map(a => {
            const data = a.payload.doc.data();
            const id = a.payload.doc.id;
           
            return { id, ...data };
          });
        }));
    }

    getBuildings(userid){

      return  this.af.collection('Buildings',ref => ref.where('userid', '==', userid)).snapshotChanges().pipe(
        map(actions => {
          return actions.map(a => {
            const data = a.payload.doc.data();
            const id = a.payload.doc.id;
           
            return { id, ...data };
          });
        }));
    }

    getUnites(){
      return  this.af.collection('Unites').snapshotChanges().pipe(
        map(actions => {
          return actions.map(a => {
            const data = a.payload.doc.data();
            const id = a.payload.doc.id;
           
            return { id, ...data };
          });
        }));
    }

    updateUnite(id){

    }
    getAllsoldier(city){
      return  this.af.collection('Casualty',
      ref => ref.where('cityCode', '==', city)
      ).snapshotChanges().pipe(
        map(actions => {
          return actions.map(a => {
            const data = a.payload.doc.data();
            const id = a.payload.doc.id;
           
            return { id, ...data };
          });
        }));
    }

    loadAndrta(){
      return  this.af.collection('Monuments').snapshotChanges().pipe(
        map(actions => {
          return actions.map(a => {
            const data = a.payload.doc.data();
            const id = a.payload.doc.id;
           
            return { id, ...data };
          });
        }));
    }
    


addMonuments(Monuments){
      return this.af.collection('Monuments').add(Monuments);

}

addSoldier(){
  return this.af.collection('Casualty').add(this.soldier);

}

soDelete(id){
  
        return this.af.collection("Casualty").doc(id).delete();

}

deletFlor(id){

  return this.af.collection("floors").doc(id).delete();

}
deletAndarta(id){
  
  return this.af.collection("Monuments").doc(id).delete();

}


updateSoldier(type,event,id){
  console.log(type,event,id);
  var NeedToUpdate={[type]:event}
  var Casualty = this.af.collection('Buildings').doc(id);
  return Casualty.update(NeedToUpdate);


}
updateFloor(data,id){
 
  var NeedToUpdate=data;
  var Casualty = this.af.collection('floors').doc(id);
  return Casualty.update(NeedToUpdate);


}

updateFloorName(data,id){
 
  var NeedToUpdate=data;
  var Casualty = this.af.collection('floors').doc(id);
  return Casualty.update(NeedToUpdate);


}

updateAdds(data,id){
 
  // var NeedToUpdate=data;
  // var Casualty = this.af.collection('Buildings').doc(id).ref("");
  // return Casualty.update(NeedToUpdate);


}





updateSolderAndarta(){

}
















      // http://localhost:3000/api/ExhibitionByLang/5b93bae8c8445104910ae283/he
      getExhibitionByLang(id,lang){
        console.log('getExhibitionByLang');
        return this.http.get(this.ServerURL + "/api/ExhibitionByLang/" + id + "/"+ lang);
      }



    getExhibitionByLangFireBase(id,lang){
      console.log('getExhibitionByLang');
      console.log("id",id)

      var t:any;
      return this.af.collection('Exhibition',ref=>{
        return ref.where("lang","==",lang).where("muzeum","==",id)
       }).snapshotChanges().pipe(
        map(actions => {
          return actions.map(a => {
            const data = a.payload.doc.data();
            const id = a.payload.doc.id;
           
            return { id, ...data };
          });
        }));

      
   //   console.log('getExhibitionByLang',newCollection);
    // // return this.af.collection('Exhibition',ref=>{
    //  return ref.where("lang","==",lang).where("muzeum","==",id)
    // }).valueChanges();

    // return this.af.collection('Exhibition',ref=>{
    //   return ref.where("lang","==",lang).where("muzeum","==",id)
    //  }).snapshotChanges();


  }
    // http://localhost:3000/api/spotID/5b96684e174f465f8f200b68
    getSpotByID(id){
      console.log('getSpotByID',id);
      return this.http.get(this.ServerURL + "/api/spotID/" + id);
    }
    getSpotByIDFirebase(id){
      console.log('getSpotByID',id);
  
    }

  //router.route('/spotDelete/:spotID').get(deleteSpot);
    spotDelete(id){

      return this.af.collection("spots").doc(id).delete();
     /// return this.http.get(this.ServerURL + "/api/spotDelete/" + id);
    }

    ExDelete(id){
console.log("id to delet ",id);
console.log(this.af.collection("Exhibition").doc(id).delete());
      return this.af.collection("Exhibition").doc(id).delete();
      //return this.http.get(this.ServerURL + "/api/ExhibitionDelete/" + id);
    }

   // router.route('/spotEdit/:spotID/:field/:value').get(EditSpot);
    spotEdit(spotID,field,value){
      console.log('getExhibitionByID',value);


      //let objString = this.b64EncodeUnicode(value);
      this.choosenSpot[field]=value;

      console.log("spotID",spotID)
      return this.af.collection('spots').doc(spotID).set(this.choosenSpot); 
      //return this.http.get(this.ServerURL + "/api/spotEdit/" + spotID + "/"+field+"/"+ objString);
      
    }
   // router.route('/spotEdit/:spotID/:field/:value').get(EditSpot);
   ExhibitionEdit(spotID,field,value){
    console.log('ExhibitionEdit',this.Meuzium.lang,this.choosenLang.exhibitions,spotID,value);
    console.log('value',value);
    this.choosenEx[field]=value;
 console.log(spotID,this.choosenEx);
    return this.af.collection('Exhibition').doc(spotID).set(this.choosenEx); 
    //return this.http.get(this.ServerURL + "/api/ExhibitionEdit/" + spotID + "/"+field+"/"+ objString);
  }
  ExhibitionAddId(spotID,value){
  
    return this.af.collection('Exhibition').doc(spotID).set(value); 
    //return this.http.get(this.ServerURL + "/api/ExhibitionEdit/" + spotID + "/"+field+"/"+ objString);
  }
    
    // http://localhost:3000/api/Exhibition/5b93bc0fc8445104910ae284
    getExhibitionByID(DOMid){
      console.log('getExhibitionByID',DOMid);
      return this.http.get(this.ServerURL + "/api/Exhibition/" + DOMid);
    }

    // http://localhost:3000/api/spot/5b93bc0fc8445104910ae284/222/en
    getSpotByExebitionCodeAndLang(id,code,lang){
      console.log('getSpotByExebitionCodeAndLang',id,code,lang);
      return this.http.get(this.ServerURL + "/api/spot/" + id + "/" + code + "/"+ lang);
    }

    // http://localhost:3000/api/spot/5b93bc0fc8445104910ae284/222/en
    getSpotByExebitionAndLang(id,lang){
      console.log('getSpotByExebitionCodeAndLang');
      return this.http.get(this.ServerURL + "/api/spotBylang/" + id + "/"+ lang);
    }
    getSpotByExebitionAndLangFirebase(id,lang){
      console.log('getSpotByExebitionCodeAndLang',id,lang);
     

      return this.af.collection('spots',ref=>{
        return ref.where("lang","==",lang).where("ExhibitionsID","==",id)
       }).snapshotChanges().pipe(
        map(actions => {
          return actions.map(a => {
            console.log("a.payload ",a.payload.doc)
            const data:any = a.payload.doc.data();
            const id = a.payload.doc.id;
            data.id=id;
            return { id ,...data};
          });
        }));
    }

   // http://localhost:3000/api/Muzeum/ComicsHolon
    getMuzeumByurlCode(urlCode){
      console.log('getMuzeumByurlCode');
      return this.http.get(this.ServerURL + "/api/Muzeum/" + urlCode);
    }


  //  router.route('/addSpot/:ExhibitionsID/:bgImg/:code/:colors/:discription/:lang/:muzeum/:name/:options/:src/:visited').get(addSpot);
  addSpot(obj){
    console.log("obj",obj);
    let objString = JSON.stringify(obj);
    objString = this.b64EncodeUnicode(objString);
    console.log('objString-before',objString);
    objString = objString.replace(/[/]/g,"matan");
    console.log('objString-after',objString);



    return this.http.get(this.ServerURL + "/api/addSpot/" + objString);
  }  

  addSpotFire(obj){
    console.log("obj",obj);
    // let objString = JSON.stringify(obj);
    // objString = this.b64EncodeUnicode(objString);
    // console.log('objString-before',objString);
    // objString = objString.replace(/[/]/g,"matan");
    // console.log('objString-after',objString);


    return this.af.collection("spots").add(obj);
  
    // .then(function(docRef) {
    //     console.log("Document written with ID: ", docRef.id);
     
    // })
    // .catch(function(error) {
    //     console.error("Error adding document: ", error);
    // });

  }  


  pushFloors(floor){
    return this.af.collection("floors").add(floor);

  }

  pushFloorss(floor){
    return this.af.collection("floors").add(floor);
  }
  addSpott(obj){
  //  let objString = JSON.stringify(obj);
  // objString = this.b64EncodeUnicode(objString);
    return this.http.post(this.ServerURL + "/api/addSpott/" ,obj);
  }  


  addExFirebase(obj){
    let objString = JSON.stringify(obj);
    //objString = objString.replace(/[/]/g,"matan");
    //objString = this.b64EncodeUnicode(objString);
   // return this.http.get(this.ServerURL + "/api/ExhibitionAdd/" + objString);

    return this.af.collection("Exhibition").add(obj);
  }  

  addEx(obj){
    let objString = JSON.stringify(obj);
    objString = objString.replace(/[/]/g,"matan");
    objString = this.b64EncodeUnicode(objString);
    return this.http.get(this.ServerURL + "/api/ExhibitionAdd/" + objString);
  }  


  b64EncodeUnicode(str) {
    return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function(match, p1) {
        return String.fromCharCode(parseInt(p1, 16))
    }))
}


   languages = [
      { code : 'ab', name : 'Abkhazian' },
      { code : 'aa', name : 'Afar' },
      { code : 'af', name : 'Afrikaans' },
      { code : 'ak', name : 'Akan' },
      { code : 'sq', name : 'Albanian' },
      { code : 'am', name : 'Amharic' },
      { code : 'ar', name : 'Arabic' },
      { code : 'an', name : 'Aragonese' },
      { code : 'hy', name : 'Armenian' },
      { code : 'as', name : 'Assamese' },
      { code : 'av', name : 'Avaric' },
      { code : 'ae', name : 'Avestan' },
      { code : 'ay', name : 'Aymara' },
      { code : 'az', name : 'Azerbaijani' },
      { code : 'bm', name : 'Bambara' },
      { code : 'ba', name : 'Bashkir' },
      { code : 'eu', name : 'Basque' },
      { code : 'be', name : 'Belarusian' },
      { code : 'bn', name : 'Bengali' },
      { code : 'bh', name : 'Bihari languages' },
      { code : 'bi', name : 'Bislama' },
      { code : 'bs', name : 'Bosnian' },
      { code : 'br', name : 'Breton' },
      { code : 'bg', name : 'Bulgarian' },
      { code : 'my', name : 'Burmese' },
      { code : 'ca', name : 'Catalan, Valencian' },
      { code : 'km', name : 'Central Khmer' },
      { code : 'ch', name : 'Chamorro' },
      { code : 'ce', name : 'Chechen' },
      { code : 'ny', name : 'Chichewa, Chewa, Nyanja' },
      { code : 'zh', name : 'Chinese' },
      { code : 'cu', name : 'Church Slavonic, Old Bulgarian, Old Church Slavonic' },
      { code : 'cv', name : 'Chuvash' },
      { code : 'kw', name : 'Cornish' },
      { code : 'co', name : 'Corsican' },
      { code : 'cr', name : 'Cree' },
      { code : 'hr', name : 'Croatian' },
      { code : 'cs', name : 'Czech' },
      { code : 'da', name : 'Danish' },
      { code : 'dv', name : 'Divehi, Dhivehi, Maldivian' },
      { code : 'nl', name : 'Dutch, Flemish' },
      { code : 'dz', name : 'Dzongkha' },
      { code : 'en', name : 'English' },
      { code : 'eo', name : 'Esperanto' },
      { code : 'et', name : 'Estonian' },
      { code : 'ee', name : 'Ewe' },
      { code : 'fo', name : 'Faroese' },
      { code : 'fj', name : 'Fijian' },
      { code : 'fi', name : 'Finnish' },
      { code : 'fr', name : 'French' },
      { code : 'ff', name : 'Fulah' },
      { code : 'gd', name : 'Gaelic, Scottish Gaelic' },
      { code : 'gl', name : 'Galician' },
      { code : 'lg', name : 'Ganda' },
      { code : 'ka', name : 'Georgian' },
      { code : 'de', name : 'German' },
      { code : 'ki', name : 'Gikuyu, Kikuyu' },
      { code : 'el', name : 'Greek (Modern)' },
      { code : 'kl', name : 'Greenlandic, Kalaallisut' },
      { code : 'gn', name : 'Guarani' },
      { code : 'gu', name : 'Gujarati' },
      { code : 'ht', name : 'Haitian, Haitian Creole' },
      { code : 'ha', name : 'Hausa' },
      { code : 'he', name : 'עברית' },
      { code : 'hz', name : 'Herero' },
      { code : 'hi', name : 'Hindi' },
      { code : 'ho', name : 'Hiri Motu' },
      { code : 'hu', name : 'Hungarian' },
      { code : 'is', name : 'Icelandic' },
      { code : 'io', name : 'Ido' },
      { code : 'ig', name : 'Igbo' },
      { code : 'id', name : 'Indonesian' },
      { code : 'ia', name : 'Interlingua (International Auxiliary Language Association)' },
      { code : 'ie', name : 'Interlingue' },
      { code : 'iu', name : 'Inuktitut' },
      { code : 'ik', name : 'Inupiaq' },
      { code : 'ga', name : 'Irish' },
      { code : 'it', name : 'Italian' },
      { code : 'ja', name : 'Japanese' },
      { code : 'jv', name : 'Javanese' },
      { code : 'kn', name : 'Kannada' },
      { code : 'kr', name : 'Kanuri' },
      { code : 'ks', name : 'Kashmiri' },
      { code : 'kk', name : 'Kazakh' },
      { code : 'rw', name : 'Kinyarwanda' },
      { code : 'kv', name : 'Komi' },
      { code : 'kg', name : 'Kongo' },
      { code : 'ko', name : 'Korean' },
      { code : 'kj', name : 'Kwanyama, Kuanyama' },
      { code : 'ku', name : 'Kurdish' },
      { code : 'ky', name : 'Kyrgyz' },
      { code : 'lo', name : 'Lao' },
      { code : 'la', name : 'Latin' },
      { code : 'lv', name : 'Latvian' },
      { code : 'lb', name : 'Letzeburgesch, Luxembourgish' },
      { code : 'li', name : 'Limburgish, Limburgan, Limburger' },
      { code : 'ln', name : 'Lingala' },
      { code : 'lt', name : 'Lithuanian' },
      { code : 'lu', name : 'Luba-Katanga' },
      { code : 'mk', name : 'Macedonian' },
      { code : 'mg', name : 'Malagasy' },
      { code : 'ms', name : 'Malay' },
      { code : 'ml', name : 'Malayalam' },
      { code : 'mt', name : 'Maltese' },
      { code : 'gv', name : 'Manx' },
      { code : 'mi', name : 'Maori' },
      { code : 'mr', name : 'Marathi' },
      { code : 'mh', name : 'Marshallese' },
      { code : 'ro', name : 'Moldovan, Moldavian, Romanian' },
      { code : 'mn', name : 'Mongolian' },
      { code : 'na', name : 'Nauru' },
      { code : 'nv', name : 'Navajo, Navaho' },
      { code : 'nd', name : 'Northern Ndebele' },
      { code : 'ng', name : 'Ndonga' },
      { code : 'ne', name : 'Nepali' },
      { code : 'se', name : 'Northern Sami' },
      { code : 'no', name : 'Norwegian' },
      { code : 'nb', name : 'Norwegian Bokmål' },
      { code : 'nn', name : 'Norwegian Nynorsk' },
      { code : 'ii', name : 'Nuosu, Sichuan Yi' },
      { code : 'oc', name : 'Occitan (post 1500)' },
      { code : 'oj', name : 'Ojibwa' },
      { code : 'or', name : 'Oriya' },
      { code : 'om', name : 'Oromo' },
      { code : 'os', name : 'Ossetian, Ossetic' },
      { code : 'pi', name : 'Pali' },
      { code : 'pa', name : 'Panjabi, Punjabi' },
      { code : 'ps', name : 'Pashto, Pushto' },
      { code : 'fa', name : 'Persian' },
      { code : 'pl', name : 'Polish' },
      { code : 'pt', name : 'Portuguese' },
      { code : 'qu', name : 'Quechua' },
      { code : 'rm', name : 'Romansh' },
      { code : 'rn', name : 'Rundi' },
      { code : 'ru', name : 'Russian' },
      { code : 'sm', name : 'Samoan' },
      { code : 'sg', name : 'Sango' },
      { code : 'sa', name : 'Sanskrit' },
      { code : 'sc', name : 'Sardinian' },
      { code : 'sr', name : 'Serbian' },
      { code : 'sn', name : 'Shona' },
      { code : 'sd', name : 'Sindhi' },
      { code : 'si', name : 'Sinhala, Sinhalese' },
      { code : 'sk', name : 'Slovak' },
      { code : 'sl', name : 'Slovenian' },
      { code : 'so', name : 'Somali' },
      { code : 'st', name : 'Sotho, Southern' },
      { code : 'nr', name : 'South Ndebele' },
      { code : 'es', name : 'Spanish, Castilian' },
      { code : 'su', name : 'Sundanese' },
      { code : 'sw', name : 'Swahili' },
      { code : 'ss', name : 'Swati' },
      { code : 'sv', name : 'Swedish' },
      { code : 'tl', name : 'Tagalog' },
      { code : 'ty', name : 'Tahitian' },
      { code : 'tg', name : 'Tajik' },
      { code : 'ta', name : 'Tamil' },
      { code : 'tt', name : 'Tatar' },
      { code : 'te', name : 'Telugu' },
      { code : 'th', name : 'Thai' },
      { code : 'bo', name : 'Tibetan' },
      { code : 'ti', name : 'Tigrinya' },
      { code : 'to', name : 'Tonga (Tonga Islands)' },
      { code : 'ts', name : 'Tsonga' },
      { code : 'tn', name : 'Tswana' },
      { code : 'tr', name : 'Turkish' },
      { code : 'tk', name : 'Turkmen' },
      { code : 'tw', name : 'Twi' },
      { code : 'ug', name : 'Uighur, Uyghur' },
      { code : 'uk', name : 'Ukrainian' },
      { code : 'ur', name : 'Urdu' },
      { code : 'uz', name : 'Uzbek' },
      { code : 've', name : 'Venda' },
      { code : 'vi', name : 'Vietnamese' },
      { code : 'vo', name : 'Volap_k' },
      { code : 'wa', name : 'Walloon' },
      { code : 'cy', name : 'Welsh' },
      { code : 'fy', name : 'Western Frisian' },
      { code : 'wo', name : 'Wolof' },
      { code : 'xh', name : 'Xhosa' },
      { code : 'yi', name : 'Yiddish' },
      { code : 'yo', name : 'Yoruba' },
      { code : 'za', name : 'Zhuang, Chuang' },
      { code : 'zu', name : 'Zulu' }
  ];
  




}
