import { DataService } from './../data.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  username="";
  password="";
  constructor(private _DataService:DataService,private router:Router) { }

  ngOnInit() {
  }

  clickEnter(event){
  

  
    this._DataService.getUserFirebase(this.username,this.password).subscribe((res:any) => {
        console.log(res);
        if(res.length > 0){
          
          localStorage.setItem('userid', res[0].userid);
         this.router.navigate(['/edit/'])
          // this._DataService.loggedIn(res);
        }else{
          console.log('here not Auth',res);
          alert("Username or Password is incorrect")
        }
      });
   


}}
