import { Observable } from 'rxjs';
import { AngularFireStorage, AngularFireStorageReference, AngularFireUploadTask } from 'angularfire2/storage';
import { DataService } from './../data.service';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ChangeEvent } from '@ckeditor/ckeditor5-angular/ckeditor.component';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';



@Component({
  selector: 'app-edityad',
  templateUrl: './edityad.component.html',
  styleUrls: ['./edityad.component.css']
})
export class EdityadComponent implements OnInit {

  userid:string;
  showbuilding=false;
  BuildingArray=[];
  coverbuilding="";
  logobuilding="";
  buildingUrl="https://app.digitaloffice.co.il/home/";
  buldingSettings=[{
    name:"להציג",
    data:true
  },{ 
    name:"לא להציג",
    data:false}]


  bulding = {
          id:"",
          userId:"",
          user:"",
          password:"",
          logo:"",
          cover:"",
          Bname:"",
          subname:"",
          setting:{
              rssWeather:true,
              rssNews:false,
              search:true,
              rssDate:true,
              rssTime:true,
              mode:"dark",
        style:1
          },
          smallCarousel:[{ 
              img:"",
              order:"",
              show:"",
          }],
        
          bigCarousel:[{ 
            img:"",
            order:"",
            show:"",
        }]
         
      };


     floors = [{
        header: '',
        items: [{
            name: 'הלל אדריכלות ',
            floor:22
        }]
      }]




  ///old old 
  city="bat-yam";
  Halal="";
  iPeople=0;
  searhArrary=[];
  andarta={
    pic:"",
    text:""
  }



  uploadProgress3: Observable<number>;
  uploadProgress2: Observable<number>;
  uploadProgress: Observable<number>;
  task: AngularFireUploadTask;
  progImg=false;
  progImg2=false;
  progImg3=false;
  ref: AngularFireStorageReference;
  public Editor = ClassicEditor;

  public onChange( { editor }: ChangeEvent ,type,id) {
    const data = editor.getData();


   
    this.dataService.updateSoldier(type,data,id).then(x=>{
      this.showSuccess("נשמר");
    })

    // console.log( data );
}

Andartan="הוסף אנדרטה";
  solderModal=false;
  soliderArray;
  soliderArray2;


 
  soldier = {
    andarta: [""],
    breavePlace: "",
    cityCode: this.city,
    date: "",
    fName: "",
    fallingdate: "",
    lName: "",
    level: "",
    mapB: "",
    justdate:"",
    helka:"",
    perents: "",
    kvura:"",
    profile: "",
    story: "",
    unit: "",
    unitLogo:{
      uName:"",
      uImg:""
    }

  };
  unites=[{
    uName:"",
    uImg:""
  }]

  constructor(public toastr: ToastrManager,private dataService: DataService, private afStorage: AngularFireStorage,public sanitizer:DomSanitizer) { }

  ngOnInit() {


this.userid = localStorage.getItem('userid');

if(this.userid){

  this.dataService.getBuildings(this.userid).subscribe(response=>{

    console.log(response);
    this.BuildingArray=response;

  })
}


  }

  changeUnit(x,id,type){

console.log(x);


this.bulding.setting[type]=x;
this.dataService.updateSoldier('setting',this.bulding.setting,id).then(x=>{});

  }
  addNewSolder(){
    this.dataService.addSoldier().then(x=>{
      console.log(x.id)
    })
  }

  editData(data){


    
    this.bulding=data;

this.dataService.loadFloorsByid(this.bulding.id).subscribe(res=>{
  var data:any=res;

  data.sort((a, b) => parseFloat(a.order) - parseFloat(b.order));
  this.floors=data;
})
    this.coverbuilding=this.bulding.cover;
    this.logobuilding=this.bulding.logo;
this.solderModal=true;
  }




  deletImg(e,x,id,type){
e.splice(x,1);

this.dataService.updateSoldier(type,e,id).then(x=>{
  this.showSuccess("נשמר")
})
  }


  deletcomp(e,x){


  

    e.items.splice(x, 1);
    console.log(e,e.id);
    this.dataService.updateFloor(e,e.id).then(x=>{
      this.showSuccess("נשמר")
    })

  }

  addfloor(bid,order){
    // this.dataService.addFloor();
   var x={
      "buildingid": bid,
      "order":order,
      "header":"new",
      "items":[{
        "name":"new",

      }]
    }

    this.dataService.pushFloors(x)
  }


  getUrl(post)
{
  return this.sanitizer.bypassSecurityTrustResourceUrl(''+ post +'/');
}


showbuild(){
  this.showbuilding=!this.showbuilding;
}
  deletFlor(idfloor){

    this.dataService.deletFlor(idfloor.id);
  }
  changeflorname(e,x,index,type){
    var newfloors:any= x;
    newfloors.header=e;
   
   
   
   this.dataService.updateFloorName(newfloors,newfloors.id)
  //  .then(x=>{
  //    this.showSuccess("נשמר")
  //  })
   
   
   }

changeflor(e,x,index,type){
 var newfloors:any= x;

 if(index == newfloors.items.length){
  newfloors.items.push({
    name:"חברה חדשה",
  })
 }else{
  newfloors.items[index][type]=e;
 }


this.dataService.updateFloor(newfloors,newfloors.id).then(x=>{
  this.showSuccess("נשמר")
})


}
  changedVal(type,event,id){
    console.log(type,event,id);
    this.dataService.updateSoldier(type,event,id).then(x=>{
      this.showSuccess("נשמר")
    })
  }
  changedValEditor(type,event,id){
    console.log(type,event.getData(),id);
    // this.dataService.updateSoldier(type,event,id).then(x=>{
    //   console.log(x);
    // })
  }

  
  closeModal(e) {
    this.solderModal=false;
  }

  soldierDelet(id){
    if (confirm('בטוחים שתרצו למחוק?')) {
      var self=this;

      this.dataService.soDelete(id).then((res: any) => {
self.showSuccess("נמחק בהצלחה");
self.closeModal("E");
        
      })
    }
  }

  search(ser){
    if(ser.length > 0){
      this.soliderArray2 =this.searchSold(ser,this.soliderArray);

    }else{
      this.soliderArray2=this.soliderArray;
    }
    console.log(this.searhArrary)
  }

 searchSold(nameKey, myArray){
   var newArray=[];
    for (var i=0; i < myArray.length; i++) {
      var nameSearch = myArray[i].fName +" "+myArray[i].lName;
      
        if (nameSearch.includes(nameKey)) {
          newArray.push(myArray[i]);
        }
    }
    return newArray;
}
pushFloors(){
var flors=[];
var id="id";


var floors = [{
  header: '2',
  items: [{
      name: 'הלל אדריכלות ',
      floor:22
  }, {
      name: 'GPL',
      floor:12
  }, {
      name: 'מיסחר'
  }, {
      name: 'ד.מ. קונקט בע"מ'
  }, {
      name: 'רובי נדל"ן'
  }, {
    name: 'סיוון דיזיין'
}, {
  name: 'ניו אדג'
}, {
name: 'דניאל נויפלד בע"מ'
}, {
name: 'טוסקה ניהול והשקעות בע"מ'
},


]
}, {
  header: '3',
  items: [{
      name: 'עוז 99 פרוייקטים ונכסים בע"מ'
  }, {
      name: 'שרון פלק אחזקות בע"מ'
  }, {
      name: 'טי.אס.פאוור נכסים בע"מ'
  }, {
      name: 'אמ.איי.אי. תקשורת בע"מ'
  }, {
      name: 'ארקום טכנולוגיות בע"מ '
  }, {
    name: 'אבידן לרון בע"מ'
}, {
  name: 'יואל השקעות ונכסים בע"מ'
}, {
name: 'א.ח. רעננה נכסים בע"מ'
}, ]
},{
header: '4',
items: [{
name: 'פז בניה בע"מ'
}, {
name: 'סראלום טכנולוגיות בע"מ'
}, {
name: 'פיתוח בגן בע"מ '
}, {
name: 'אבירן שמאות מקרקעין בע"מ'
}, {
name: 'יואל נאמן עורך דין'
}, {
name: 'מנטע'
}, {
name: 'בנון אריק'
}, ]


},{
header: '5',
items: [{
  name: 'לידן נדל"ן בע"מ'
}, {
  name: 'ידין קאופמן אחזקות בע"מ'
}, {
  name: 'אינקריס בע"מ'
}, {
  name: 'א.ג.ם פלדה'
}, {
  name: 'שחטר - רנאל בע"מ'
}, {
name: 'אוגלים בע"מ'
}, {
name: 'אם. אי. גי. די גרופ בע"מ'
}, ]




},{
header: '6',
items: [{
  name: 'כנפי זהב תעופה ומימון בע"מ'
}, {
  name: 'אלבוים עופר חברת עורכי דין'
}, {
  name: 'אינוויטק פתרונות רפואיים בע"מ'
}, {
  name: 'ניאולן אסטס בע"מ'
}, {
  name: 'מי זהב השקעות בע"מ '
}, {
name: 'מרינובסקי אינטרנשיונל בע"מ'
}, ]




},{
header: '7',
items: [{
  name: 'שטרק את שטרק נכסים בע"מ'
}, {
  name: 'בי איי-בן חור אמויאל בע"מ'
}, {
  name: 'פרולוג ש.מ. נכסים בע"מ'
}]



},{
header: '8',
items: [{
  name: 'אקרו נכסים בע"מ'
}, ]



},{
header: '9',
items: [{
  name: 'טרגט פרופרטיס בע"מ'
}, ]




},{
header: '10-11',
items: [{
  name: 'ציון איי. אם. הולדינגס בע"מ'
}, ]

}]


floors.forEach(x=>{
  // x["buildingid"]="0StCnQaepGowaM3zOe4x";

  console.log(x);
  // this.dataService.pushFloorss(x);

})

return;
}
deletAndarta(chosen,place,id){


var newand=chosen.splice(place, 1);
console.log(newand,chosen,place);


this.dataService.updateSoldier("andarta",chosen,id)
}

uploadADDS(event, idsol,build,type) {


var self=this;
  self.progImg3 = true;

  const id = Math.random().toString(36).substring(2);
  this.ref = this.afStorage.ref(id);
  this.task = this.ref.put(event.target.files[0]);
  // do you code after upload here

  this.uploadProgress3 = this.task.percentageChanges()
  var self = this;
  this.task.then(snap => {
    snap.ref.getDownloadURL().then(function (downloadURL) {
     console.log(downloadURL);
     build[type].push({
       img:downloadURL
     })
     self.progImg3 = false;
     self.changedVal(type,build[type],build.id);
      //  self.coverbuilding=downloadURL;
      //  self.changedVal("cover",downloadURL,idsol);

      self.progImg3 = false;
    });
  })
}

uploadCover(event, idsol) {
  this.progImg2 = true;




  const id = Math.random().toString(36).substring(2);
  this.ref = this.afStorage.ref(id);
  this.task = this.ref.put(event.target.files[0]);
  // do you code after upload here

  this.uploadProgress2 = this.task.percentageChanges()
  var self = this;
  this.task.then(snap => {
    snap.ref.getDownloadURL().then(function (downloadURL) {
     
       self.coverbuilding=downloadURL;
       self.changedVal("cover",downloadURL,idsol);

      self.progImg2 = false;
    });
  })
}
  upload(event, idsol) {
    this.progImg = true;




    const id = Math.random().toString(36).substring(2);
    this.ref = this.afStorage.ref(id);
    this.task = this.ref.put(event.target.files[0]);
    // do you code after upload here

    this.uploadProgress = this.task.percentageChanges()
    var self = this;
    this.task.then(snap => {
      snap.ref.getDownloadURL().then(function (downloadURL) {
         self.logobuilding=downloadURL;

        self.changedVal("logo",downloadURL,idsol);

        self.progImg = false;
      });
    })
  }

  
    showSuccess(text) {
      this.toastr.successToastr(text, 'Success!');
    }
  
    showError(text) {
      this.toastr.errorToastr(text, '');
    }
  }


 



