import { AngularFireDatabase } from 'angularfire2/database';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import{ HttpModule } from '@angular/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http'; 
// import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
// import { QuillModule } from 'ngx-quill';

import { AppComponent } from './app.component';
import { DataService } from './data.service';
import { MuzeumComponent } from './muzeum/muzeum.component';
import { LangPipe } from './lang.pipe';
import { ExhibitionComponent } from './exhibition/exhibition.component';
import { PanelComponent } from './panel/panel.component';
import { AccordionComponent } from './accordion/accordion.component';
import { SafePipe } from './safe.pipe';
import { AngularFireModule } from 'angularfire2';
import { AngularFireStorageModule } from 'angularfire2/storage';
import { FileSelectDirective } from 'ng2-file-upload';
import { AngularFirestoreModule } from 'angularfire2/firestore';
// import { NgxEditorModule } from 'ngx-editor';

import { BrowserAnimationsModule ,} from '@angular/platform-browser/animations';
import { ToastrModule } from 'ng6-toastr-notifications';


import { ColorPickerModule } from 'ngx-color-picker';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { EdityadComponent } from './edityad/edityad.component';
import { LoginComponent } from './login/login.component';
import { AppRoutingModule } from './/app-routing.module';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { ModalAndartaComponent } from './modal-andarta/modal-andarta.component';


@NgModule({
  declarations: [
    AppComponent,
    
    MuzeumComponent,
    FileSelectDirective,
    LangPipe,
    ExhibitionComponent,
    PanelComponent,
    AccordionComponent,
    SafePipe,
    EdityadComponent,
    LoginComponent,
    ModalAndartaComponent
    
  ],
  
  imports: [
    // QuillModule, 
    CKEditorModule,
    HttpModule,
    AngularFireAuthModule,
    ColorPickerModule,
    AngularFirestoreModule,
    ReactiveFormsModule,
    // NgxEditorModule,
    FormsModule,
    BrowserModule, BrowserAnimationsModule, ToastrModule.forRoot(),
    HttpClientModule,
    AngularFireModule.initializeApp({
      apiKey: "AIzaSyAVbXaADg6Dtyk0l_vFcWEeZepIVlU1diU",
      authDomain: "digitaloffice-dc738.firebaseapp.com",
      projectId: "digitaloffice-dc738",
      storageBucket: "digitaloffice-dc738.appspot.com",
      messagingSenderId: "12747671299",
      appId: "1:12747671299:web:e00f897773d6d746f10b36",
      measurementId: "G-4WN3964WDJ"
    }),
    AngularFireStorageModule,
    AppRoutingModule,
    // NgbModule.forRoot()
    
  ],
  providers: [DataService],
  bootstrap: [AppComponent]
})
export class AppModule { }
