import { DataService } from './../data.service';
import { Component, OnInit } from '@angular/core';

import { Observable } from 'rxjs';
import { AngularFireStorage, AngularFireStorageReference, AngularFireUploadTask } from 'angularfire2/storage';
import { ToastrManager } from 'ng6-toastr-notifications';


@Component({
  selector: 'app-modal-andarta',
  templateUrl: './modal-andarta.component.html',
  styleUrls: ['./modal-andarta.component.css']
})
export class ModalAndartaComponent implements OnInit {
  Monuments=[];
  Monuments2 =[];
  andarta={
    pic:"",
    text:""
  }
  progImg2=false;
  ref: AngularFireStorageReference;

  uploadProgress2: Observable<number>;
  task: AngularFireUploadTask;


  constructor(private dataService:DataService,public toastr: ToastrManager, private afStorage: AngularFireStorage) { }

  ngOnInit() {
this.dataService.loadAndrta().subscribe(x=>{
  this.Monuments=x;
  this.Monuments2 =x
})
  }


  searchSold(nameKey, myArray){
    var newArray=[];
     for (var i=0; i < myArray.length; i++) {
       var nameSearch = myArray[i].text;
        
         if (nameSearch.includes(nameKey)) {
           newArray.push(myArray[i]);
         }
     }
     return newArray;
 }
  search(ser){
    if(ser.length > 0){
      console.log(ser, this.Monuments )
      this.Monuments2 =this.searchSold(ser, this.Monuments );

    }else{
      this.Monuments2 = this.Monuments ;
    }

  }

  addM(andarta){
    if(this.dataService.choseAndartaArray.length == 0 ){
      var newArray=[andarta];
      this.dataService.updateSoldier("andarta",newArray,this.dataService.chosenSoldier).then(x=>{
        
      })

    }else{


    this.dataService.choseAndartaArray.push(andarta);
    this.dataService.updateSoldier("andarta",this.dataService.choseAndartaArray,this.dataService.chosenSoldier).then(x=>{
      console.log(x);
    })
  }


  }

  deletAndarta(id){
    this.dataService.deletAndarta(id)
  }

  closeMdal(){
    this.andarta.pic="";
    this.andarta.text="";
    this.dataService.andartaModal=false;
  }
  addandarta(){
    if((this.andarta.pic)&&(this.andarta.text)){
      this.dataService.addMonuments(this.andarta);
      this.andarta.pic="";
      this.andarta.text="";


    }else{
      alert("חסר שם או תמונה")
    }
  }

uploadAndarta(event, idsol) {
  this.progImg2 = true;




  const id = Math.random().toString(36).substring(2);
  this.ref = this.afStorage.ref(id);
  this.task = this.ref.put(event.target.files[0]);
  // do you code after upload here

  this.uploadProgress2 = this.task.percentageChanges()
  var self = this;
  this.task.then(snap => {
    snap.ref.getDownloadURL().then(function (downloadURL) {
       
       self.andarta.pic=downloadURL;
      console.log(downloadURL);
      self.progImg2 = false;
    });
  })
}


}
